import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Categories = ({ categories }) => {
  return (
    <div className="widget">
      <h6 className="title">Blog Categories</h6>
      <hr />
      <ul className="link-list">
        {categories && categories.length ? (
          categories.map(item => (
            <li key={item.category}>
              <Link to={item.link}>{item.category}</Link>
            </li>
          ))
        ) : (
          <li>
            <Link to="/blog">All Posts</Link>
          </li>
        )}
      </ul>
    </div>
  )
}

Categories.propTypes = {
  categories: PropTypes.array,
  category: PropTypes.string,
  link: PropTypes.string,
}

export default Categories
