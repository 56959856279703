import React from "react"

const SubscribeForm = () => {
  return (
    <div className="widget bg-secondary p24">
      <h5 className="uppercase mb16">Subscribe Now</h5>
      <p>
        Subscribe to our newsletter for a round-up of the week's most popular
        articles.
      </p>
      <form>
        <input
          type="text"
          className="mb0"
          name="email"
          placeholder="Email Address"
        />
        <input type="submit" value="Subscribe" />
      </form>
    </div>
  )
}

export default SubscribeForm
