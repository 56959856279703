import React from "react"
import Categories from "./categories"
import RecentPosts from "./recentPosts"
import SubscribeForm from "./subscribeForm"

const defaultCategories = [
  { category: "Lifestyle", link: "/" },
  { category: "Web Design", link: "/" },
  { category: "Photography", link: "/" },
  { category: "Freelance", link: "/" },
]

const defaultPostsData = [
  {
    title: "A simple image post for starters",
    link: "/",
    createdDate: "September 23, 2015",
  },
  {
    title: "A thoughtful blockquote post on life",
    link: "/",
    createdDate: "September 07, 2015",
  },
]

const Sidebar = ({ categories, recents: posts }) => {
  return (
    <div className="col-md-3 col-md-pull-9 hidden-sm">
      <SubscribeForm />
      <Categories categories={categories || defaultCategories} />
      <RecentPosts posts={posts || defaultPostsData} />
    </div>
  )
}

export default Sidebar
